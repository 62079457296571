




import { Component, Vue } from "vue-property-decorator";
import { store } from "@/store";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
import _ from "lodash";

const startRouteGuard = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);
  if (readIsLoggedIn(store)) {
    const user = readUserProfile(store);
    if (
      to.path.startsWith("/token-login") ||
      to.path.startsWith("/logout") ||
      to.path.startsWith("/reset-password") ||
      to.path.startsWith("/test_apply") ||
      to.path.startsWith("/social_apply") ||
      to.path.startsWith("/apply")
    ) {
      next();
      return;
    }

    const progress = _.get(user, "progress", 1);
    const progress_status_code = _.get(user, "progress_status_code", null);
    const is_superuser = _.get(user, "is_superuser", false);
    if (
      readIsLoggedIn(store) &&
      progress_status_code &&
      progress_status_code !== "70" &&
      !is_superuser
    ) {
      if (progress <= 2) {
        if (progress_status_code === "21") {
          if (to.path.startsWith("/feasibility")) {
            next();
          } else {
            if (!to.path.startsWith("/not_activated")) {
              next("/not_activated");
            } else {
              next();
            }
          }
        } else if (progress_status_code === "22") {
          if (to.path.startsWith("/feasibility")) {
            next();
          } else {
            if (!to.path.startsWith("/not_feasible")) {
              next("/not_feasible");
            } else {
              next();
            }
          }
        } else {
          if (!to.path.startsWith("/feasibility")) {
            next("/feasibility");
          } else {
            next();
          }
        }
      } else if (progress <= 3) {
        if (progress_status_code === "31") {
          if (!to.path.startsWith("/contact_review")) {
            next("/contact_review");
          } else {
            next();
          }
          return;
        }
        if (!to.path.startsWith("/id_verify")) {
          next("/id_verify");
        } else {
          next();
        }
      } else if (progress <= 5) {
        if (
          !to.path.startsWith("/plans") &&
          !to.path.startsWith("/payment_failed")
        ) {
          next("/plans");
        } else {
          next();
        }
      } else if (progress == 6) {
        if (!to.path.startsWith("/contact")) {
          if (progress_status_code === "60") {
            if (!to.path.startsWith("/schedule_set")) {
              next("/schedule_set");
            } else {
              next();
            }
          } else if (progress_status_code === "61") {
            if (!to.path.startsWith("/schedule_confirmed")) {
              next("/schedule_confirmed");
            } else {
              next();
            }
          }
          //next("/contact?success=true");
        } else {
          next();
        }
      }
    } else {
      if (!to.path.startsWith("/main")) {
        next("/main");
      } else {
        next();
      }
    }
  } else if (readIsLoggedIn(store) === false) {
    if (
      !to.path.startsWith("/token-login") &&
      !to.path.startsWith("/login") &&
      !to.path.startsWith("/apply") &&
      !to.path.startsWith("/test_apply") &&
      !to.path.startsWith("/social_apply") &&
      !to.path.startsWith("/recover-password")
    ) {
      next("/login");
    } else {
      next();
    }
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }
}
